const vocab2 = [
  "\n",
  " ",
  "'",
  ",",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "Æ",
  "É",
  "Í",
  "Î",
  "Ó",
  "á",
  "â",
  "ä",
  "é",
  "ê",
  "ë",
  "í",
  "î",
  "ó",
  "ô",
  "ö",
  "ú",
  "û"
];

const indices_char = {};
indices_char.getFromCharSet = index => {
  return indices_char[index];
};

for (let i = 0; i < vocab2.length; i++) {
  let char = vocab2[i];
  indices_char[i] = char;
}
// {
//0: a,
//1: b,
//}
export default indices_char;
