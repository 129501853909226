import * as tf from "@tensorflow/tfjs";
import React from "react";
import indices_char from "./indices_char";
import char_indices from "./char_indices";

const MAX_SEQUENCE_LENGTH = 256;

const ignore = "'!\"#$%&()*+,-./:;<=>?@[\\]^_`{|}~/\t/\n'";

// # Low temperatures results in more predictable text.
// # Higher temperatures results in more surprising text.
// # Experiment to find the best setting.
const temperature = 1.0;

const INPUT_LENGTH = 256;
const CHARS_TO_GENERATE = 200;
const DIVERSITY = 0.5;

export const delay = timeout =>
  new Promise(resolve => setTimeout(resolve, timeout));

export function sample(probs, temperature) {
  return tf.tidy(() => {
    const logits = tf.div(tf.log(probs), Math.max(temperature, 1e-6));
    const isNormalized = false;
    // `logits` is for a multinomial distribution, scaled by the temperature.
    // We randomly draw a sample from the distribution.
    return tf.multinomial(probs, 1).dataSync()[0];
  });
}

export default class TensorFlowComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      generating: false,
      names: [
        "Deviniti, The Lord's Paladin",
        "Lush The Patient",
        "Kongu The Loremaster",
        "Cthugothotal The Crawling Chaos",
        "Drake, The Dead",
        "Terrorrose, The Undying",
        "Hakkar, Of The Clan Of The Wolf",
        "King Kravix",
        "Auronnj",
        "Lord Palmer the Paladin"
      ]
    };
    // this.args = parseArgs();
  }

  componentDidMount() {
    if (!this.model) this.loadModel();
  }

  componentWillUnmount() {
    if (this.model) {
      this.model = null;
    }
  }
  // https: //firebasestorage.googleapis.com/v0/b/designs-77622.appspot.com/o/tfjs-lotr-compr-1%2Fgroup1-shard1of1.bin?alt=media&token=3bc74e35-49c1-4df7-89ec-5ae1de52f72e
  loadModel = async () => {
    this.setState({ loading: true });
    this.model = await tf.loadLayersModel(
      "https://storage.googleapis.com/pen-and-paper-campaign-companion-storage/tfjs-lotr-compr/model.json"
    );
    this.setState({ loading: false });
  };

  getSummary = () => {
    if (!this.model) return null;
    console.log("model.summary()", this.model.summary());
    // return this.model.summary()
  };

  parseNames = text => {
    let names = text.split("\n");
    this.setState({ names, generating: false });
  };

  beginGenerateText = async e => {
    this.setState({ generating: true });
    await delay(500);
    let generated = await this.generateText(
      this.model,
      indices_char,
      [0, 3, 4],
      200,
      1
    );
    console.log("generated:", generated);
    this.parseNames(generated);
  };

  generateText = async (
    model,
    textData,
    sentenceIndices,
    length,
    temperature,
    onTextGenerationChar
  ) => {
    const sampleLen = 1; //model.inputs[0].shape[1];
    const charSetSize = 71; // model.inputs[0].shape[2];
    console.log("sampleLen:", sampleLen);
    console.log("charSetSize", charSetSize);
    console.log("model:", model);

    // Avoid overwriting the original input.
    sentenceIndices = sentenceIndices.slice();

    let generated = "";
    let input_eval = tf.expandDims(sentenceIndices, 0);
    while (generated.length < length) {
      // Call model.predict() to get the probability values of the next
      // character.
      let predictions = model.predict(input_eval);
      predictions = tf.squeeze(predictions, 0);

      // Sample randomly based on the probability values.
      const predicted_id = sample(predictions, temperature);
      // console.log("predicted_id:", predicted_id)
      const winnerChar = textData.getFromCharSet(predicted_id);
      if (onTextGenerationChar != null) {
        await onTextGenerationChar(winnerChar);
      }

      generated += winnerChar;
      sentenceIndices = sentenceIndices.slice(1);
      sentenceIndices.push(predicted_id);
      // predictions = predictions / temperature;
      //   let predicted_id = tf.multinomial(predictions, 1);
      // console.log('PREDITED_ID:', predicted_id)

      // Memory cleanups.
      // input_eval.dispose();
      // predictions.dispose();

      input_eval = tf.expandDims([predicted_id], 0);
    }
    return generated;
  };

  render() {
    const { loading, names, generating } = this.state;
    return this.props.render({
      runModel: this.beginGenerateText,
      loadModel: this.loadModel,
      model: this.model,
      loading,
      generating,
      getSummary: this.getSummary,
      names
    });
  }
}
