import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { LogoImg } from "../components/Logo";
import AmazonDetailListing from "../components/AmazonListing";
import Header from "../components/Header";
import DoddsHeader from "../components/DoddsHeader";

import {
  Layout,
  Article,
  Wrapper,
  Button,
  SectionTitle,
  Subline,
  Content,
  Hero
} from "../components";
import { StyledH3, SecondaryH2 } from "../components/StyledElements";
import TensorFlowComponent from "../tensorflow";

// const Content = styled.div`
//   grid-column: 2;
//   text-align: center;
//   ${"" /* box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1); */}
//   border-radius: 1rem;
//   padding: 3rem 6rem;
//   @media (max-width: ${props => props.theme.breakpoints.tablet}) {
//     padding: 3rem 2rem;
//   }
//   @media (max-width: ${props => props.theme.breakpoints.phone}) {
//     padding: 2rem 1.5rem;
//   }
//   overflow: hidden;
// `;

// const Hero = styled.div`
//   grid-column: 2;
//   padding: 3rem 2rem 6rem 2rem;
//   text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
//   color: ${props => props.theme.colors.white};

//   @media (max-width: ${props => props.theme.breakpoints.phone}) {
//     padding: 2rem 1rem 4rem 1rem;
//   }

//   p {
//     font-size: 1.68rem;
//     margin-top: -1rem;
//     @media (max-width: ${props => props.theme.breakpoints.tablet}) {
//       font-size: 1.45rem;
//     }
//     @media (max-width: ${props => props.theme.breakpoints.phone}) {
//       font-size: 1.25rem;
//     }
//   }
// `;

const IndexPage = ({}) => (
  <Layout>
    <Wrapper>
      <TensorFlowComponent
        render={({
          loadModel,
          model,
          loading,
          getSummary,
          runModel,
          names,
          generating
        }) => {
          return (
            <React.Fragment>
              <div
                css={css`
                  grid-area: header;
                `}
              >
                <DoddsHeader />
              </div>
              <Content>
                <LogoImg src={logoImg} alt={"Fantasy Name Machine logo"} />
                <SectionTitle>
                  <Button big onClick={runModel}>
                    <svg
                      width="1792"
                      height="1792"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5t-11.5-36.5v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59l1664-960q15-9 32-9 20 0 36 11z" />
                    </svg>
                    Generate Names
                  </Button>
                  {loading && <Subline>LOADING</Subline>}
                  {generating && <Subline>algorithming..</Subline>}
                </SectionTitle>
                {names.map(name => {
                  return <div key={name}>{name}</div>;
                })}
              </Content>
            </React.Fragment>
          );
        }}
      />
    </Wrapper>
  </Layout>
);

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    })
  }).isRequired
};

// export const IndexQuery = graphql`
//   query IndexQuery {
//     allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt(pruneLength: 200)
//         timeToRead
//         fields {
//           slug
//         }
//         frontmatter {
//           title
//           date(formatString: "MM/DD/YYYY")
//           categories
//           primaryColor
//           banner {
//             ...bannerImage640
//           }
//         }
//       }
//     }
//   }
// `;
